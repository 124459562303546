.main-card-image img {
  height: 270px !important;
  width: 100% !important;
}

.card-container img {
  width: 100%;
  max-width: 40vw;
  height: 170px !important;
  max-height: 450px !important;
  border-radius: 10px !important;
}
