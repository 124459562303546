:root {
  --font-family-poppins: "Poppins", sans-serif;
  --principal-color-page: #32bcad;
  --header-backgound-color: #f0f0f0;
  --background-color: #f0f0f0;
  --dark-orange: #e0512d;
  --orange-color: #e46f4b;
  --green-color: #418b4b;
  --pink-color: hsla(334.52, 51.77%, 72.35%, 1);
  --list-section-title-color: hsla(334.52, 51.77%, 72.35%, 1);
  --primary-text-color: #000;
  --secondary-text-color: #808080;
}

/*
Override the font family for the whole page, but not for icons.
*/
* :not(i.icon) {
  font-family: var(--font-family-poppins) !important;
}

#root .ui.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#root .ui.grid {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
