.slick-track {
  margin: auto 0;
}

.arrow-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.slick-next {
  background: url("../../assets/Icons/right-arrow.png") center center no-repeat !important;
}

.slick-prev {
  background: url("../../assets/Icons/left-arrow.png") center center no-repeat !important;
}

.slick-slide img {
  border-radius: 10px !important;
}

.slick-slide.slick-cloned {
  display: none;
}

.slick-arrow {
  width: 20px;
  height: 40px;
  object-fit: cover;
  &::before {
    display: none;
  }
}
