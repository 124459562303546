.search .input {
  width: 100%;
}

.results {
  /* width: 100%; */
  width: "280px";
  height: 50vh;
  overflow-y: scroll;
}
