.main-row-container {
  .slick-arrow {
    top: 40%;
  }

  .slick-prev {
    left: -35px;
  }

  .slick-next {
    right: -35px;
  }
}

.experience-row-container {
  .slick-arrow {
    top: 35%;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }
}

.experiences-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 0;
}
